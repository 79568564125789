<template>
  <v-row class="evi-auth">
    <vue-particles
        class="evi-auth__particles"
        color="#A1E9B7"
        linesColor="#A1E9B7"
        :clickEffect="false"
        hoverMode="repulse"
        :particlesNumber="80"
    />
    <v-col cols="6" class="evi-auth__logo-wrapper">
      <div class="evi-auth__logo">
        <img width="69" class="mr-2" :src="require('@/assets/logo.svg')" alt="">
        <span class="logo-text">EviSales CRM</span>
      </div>
    </v-col>
    <v-col
        cols="4"
        class="d-flex justify-center align-center evi-auth__reg"
    >
      <v-form ref="form" v-model="valid">
        <div class="evi-auth__window">
          <div class="evi-auth__mobile-logo mb-5">
            <img width="45" class="mr-2" :src="require('@/assets/logo.svg')" alt="">
            <span class="logo-text">EviSalesCRM</span>
          </div>
          <h3>Авторизация</h3>
          <v-text-field
              v-model="user.email"
              label="Email"
              placeholder="Введите Email"
              class="evi-text-field"
              color="#44D370"
              :rules="emailRules"
              @keydown.enter.exact.prevent="login"
          />
          <v-text-field
              v-model="user.password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              label="Пароль"
              placeholder="Введите пароль"
              @click:append="show = !show"
              class="evi-text-field"
              color="#44D370"
              @keydown.enter.exact.prevent="login"
          />
          <v-btn class="evi-button-green evi-button-green--fill" height="40" @click="login()"
                 :disabled="loginButtonDisabled">
            Войти
          </v-btn>
          <a class="password-repair mt-5" @click="$router.push({path: 'password-change'})">Восстановить пароль</a>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Auth",
  data: () => ({
    user: {
      email: '',
      password: '',
    },
    show: false,
    valid: true,
    prevRoute: '',
  }),
  computed: {
    emailRules() {
      return [
        (v) => !!v || 'Введите почту',
        (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неверный формат эл. почты',
      ];
    },
    loginButtonDisabled() {
      return !this.user.email || !this.user.password;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  methods: {
    login() {
      this.$store.dispatch('user/login', {
        email: this.user.email.toLowerCase(),
        password: this.user.password,
      }).then(() => {
        this.$router.push({path: '/'});
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
